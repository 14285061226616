<template>
	<div class="h-100">
		<el-row class="is-flex js-center ai-center h-100">
			<el-col :span="12" class="relative">
				<div :class="clickHover ? 'animated animate-spin infinite slow removeBoxshadow ' : 'animateBoxShadowInfinit'" class="cardPayment cr-pointer is-flex ai-center js-center mg-x-auto" @mousedown="[modalCard ? '' : delaySpiner(true),mouseDown++]" @mouseup="modalCard ? '' : delaySpiner(false)" @touchstart="modalCard ? '' : delaySpiner(true)" @touchend="modalCard ? '' : delaySpiner(false)" @touchcancel="modalCard ? '' : delaySpiner(false)" @mouseleave="mouseDown == 1 ? delaySpiner(false):''">
					<div class="top-left"></div>
					<div class="top-right"></div>
					<div class="bottom-left"></div>
					<p  v-if="modalCard == false" class="font-24 color-primary has-gutter">Business Event</p>
					<div v-if="delayCss && modalCard" class="animated fadeInUp" style="padding:0px 20px;margin-top: -40px;">
	                	<h2 class="color-primary mg-b-5 mg-t-0">Business Event</h2> 
	                	<p class="color-primary mg-less">{{cardSelect.Detail}}</p>
	                <!-- 	<p class="color-primary mg-less">{{cardSelect.Action}}</p> -->
	                </div>
				</div>
				<div class="text-center mg-t-1 animated fadeInUp" v-if="delayCss && modalCard" style="position: absolute;width: 100%;">
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="nextStep()">Next</el-button>
                    </span>
                </div>
			</el-col>
		</el-row>
		  <!-- <el-dialog :visible.sync="modalCard" @close="setDelay()" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" class="animated animate-spin event-modal" >
		  	<div class="top-left"></div>
			<div class="top-right"></div>
			<div class="bottom-left"></div>
            <div class="modalEventCard " :class="delayCss ? 'block' :'' ">
                <div v-if="delayCss" class="animated fadeInUp" style="margin-top:55px;">
                	<h2 class="color-primary mg-b-5">Business Event</h2> 
                	<p class="color-primary">{{cardSelect.Detail}}</p>
                	<p class="color-primary">{{cardSelect.Action}}</p>
                </div>
                <div class="text-right " style="position: absolute;bottom: -18%;width: 100%;text-align: center;left:0;">
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="nextStep()">{{room.currentRound == 6 ? `World's demand` : 'Next'}}</el-button>
                    </span>
                </div>
            </div> 
        </el-dialog> -->
	</div>
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
export default {
	data(){
		return{
			mouseDown:0,
			modalCard:false,
			payment:[],
			delayCss:false,
			clickHover:false,
			cardSelect:''
		}
	},
	computed:{
		groupAllData(){
	        return this.$store.state.groupAllData
	    },
		accounts(){
	        if(this.groupAllData === null){ return '' }
	        let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
	        if(filter.length > 0){
	            return filter[0]
	        }else{
	            return ''
	        }
	      },
		user(){
			return this.$store.state.user
		},
		room(){
			return this.$store.state.room
		},
		cardData(){
	      return this.$store.state.cardData
	    }
	},
	methods:{
		delaySpiner(bool){
			if(bool == false){
				this.mouseDown++
				this.start()
				setTimeout(()=>{
					this.clickHover = false
				},1500)
			}else if(this.mouseDown < 1){
				this.clickHover = true
			}
		},
		nextStep(){
			this.loadingConfirm = true
		      let data = {
		        roomId: this.room._id,
		        round: this.room.currentRound,
		        users:[
		        	{
		        		cardId:this.cardSelect.cardId,
		        		groupId:this.user._id
		        	}
		        ]
		      }
		      HTTP.post('/business/event/card',data).then(async (res)=>{
		        if(res.data.success){
		          this.room.currentRound == 6 ? this.room.current = "World's demand" : this.room.current = "Investment";
		          await this.$store.dispatch("updateCurrent", this.room);
		          await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
		          await this.$socket.client.emit('getCardsId',{roomId:this.room._id,round:this.room.currentRound})
		          this.$router.push("/players").catch(err => {})
		        }
		      })
		      .finally(()=>{
		        setTimeout(() => {
		          this.loadingConfirm = false
		        }, 500);
		      })
			this.$store.dispatch("updateCurrent", this.room);
        	this.$router.push("/players").catch(err => {})
		},
		setDelay(){
            setTimeout(()=>{
                this.delayCss = false
            },5000)
        },
		start(){
			let value = [];
			for (var i = 0; i < this.cardData.length; i++) {
				value.push(i)
			}
          	let random = value[Math.floor(value.length * Math.random())]
          	this.cardSelect = this.cardData[random]
          	this.modalCard = true
          	setTimeout(()=>{
                this.delayCss = true
            },1000)
	    },
	}
}
</script>
<style scoped>
	.top-right{
		position: absolute;
	    right: 0;
	    top: 0;
	    z-index: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 140px 175px 0;
		border-color: transparent #19294B transparent transparent;
	}
	.bottom-left{
		position: absolute;
	    left: 0;
	    bottom: 0;
	    z-index: 0;
	    width: 0;
		height: 0;
		border-style: solid;
		border-width: 175px 0 0 140px;
		border-color: transparent transparent transparent #113C9B;
	}
	.top-left{
		position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 100px 100px 0 0;
		border-color: #4C151D transparent transparent transparent;
	}
	.cardPayment{
		overflow: hidden;
		position: relative;
		width: 300px;
		height: 500px;
		border:1px solid #000;
		box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #E0E617 0px 0px 20px, #E0E617 0px 0px 30px, #E0E617 0px 0px 40px, #E0E617 0px 0px 50px, #E0E617 0px 0px 75px;
	}
	.animateBoxShadowInfinit{
		animation-name: boxShadow;
  		animation-duration: 1s;
  		animation-iteration-count: infinite;
	}
	.animateBoxShadow{
		animation-name: boxShadowHover;
  		animation-duration: 3s;
  		animation-iteration-count: 1;
	}

	@keyframes boxShadow {
	  from {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #fff 0px 0px 20px, #fff 0px 0px 30px, #fff 0px 0px 40px, #fff 0px 0px 50px, #fff 0px 0px 75px;}
	  to {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #E0E617 0px 0px 20px, #E0E617 0px 0px 30px, #E0E617 0px 0px 40px, #E0E617 0px 0px 50px, #E0E617 0px 0px 75px;}
	}

	@keyframes boxShadowHover {
	  from {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #fff 0px 0px 20px, #fff 0px 0px 30px, #fff 0px 0px 40px, #fff 0px 0px 50px, #fff 0px 0px 75px;}
	  to {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #BEBA14 0px 0px 20px, #BEBA14 0px 0px 30px, #BEBA14 0px 0px 40px, #BEBA14 0px 0px 50px, #BEBA14 0px 0px 75px;}
	}
</style>